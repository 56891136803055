import Layout from '@/layout'
export default [
    {
        path: '/',
        component: Layout,
        children: [
            {
                path: '/',
                component: () => import('@/views/index/index'),
                name: 'home',
                meta: { title: '蓝鲸读书汇'}
            }
        ]
    },
    {
        path: '/list',
        component: Layout,
        children: [
            {
                path: '/',
                component: () => import('@/views/list/list'),
                name: 'list',
                meta: { title: '蓝鲸读书汇'}
            }
        ]
    },
    {
        path: '/article',
        component: Layout,
        children: [
            {
                path: '/',
                component: () => import('@/views/article/article'),
                name: 'article',
                meta: { title: '蓝鲸读书汇'}
            }
        ]
    },
    {
        path: '/search',
        component: Layout,
        children: [
            {
                path: '/',
                component: () => import('@/views/search/search'),
                name: 'search',
                meta: { title: '蓝鲸读书汇'}
            }
        ]
    },


]