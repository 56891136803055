<template>
  <div>
    <div class="page-header">
      <div class="header-view">
        <div>
          <img class="head-logo" src="@/assets/logo.svg" alt="蓝鲸读书汇"/>
        </div>
        <div class="header-text-view">
          <p>北京师范大学儿童阅读与学习研究院</p>
          <p>蓝鲸读书汇教育科技（北京）有限公司</p>
        </div>
        <div class="header-union">
          <p>联合出品</p>
        </div>
      </div>
      <div class="header-search">
        <el-input class="search-input" v-model="keyword" placeholder="请输入内容"></el-input>
        <el-button icon="el-icon-search" @click="search"></el-button>
      </div>

    </div>
    <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect" background-color="#1563AA" text-color="white" active-text-color="white">
      <el-menu-item index="0">首页</el-menu-item>
      <el-menu-item v-for="(item, index) in menu" :key="index" :index="item.id+''">{{ item.name }}</el-menu-item>
    </el-menu>
    <img v-if="nowMenu !== ''" :src="ossUrl + nowMenu.photo" width="100%" />
    <div class="page-content" v-if="nowMenu.type === 0" v-html="nowMenu.content"></div>
  </div>
</template>

<script>
import {columnList} from "@/api/common";
import configs from "@/settings";
export default {
  name: "Header",
  data() {
    return {
      activeIndex: '0',
      keyword: '',
      menu: [],
      ossUrl: configs.ossUrl,
      nowMenu: "",
    }
  },
  created() {
    this.getMenu();
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key);
      console.log(keyPath);
      if(key !== 0) {
        this.nowMenu = this.findMenu(key);
        console.log(this.nowMenu);
      }
      console.log(this.nowMenu);
      this.activeIndex = keyPath+""
      if(this.nowMenu === ""){
        this.$router.push('/');
      }else{
        this.$router.push({path:'/list',query:{id:this.nowMenu.id}});
      }
    },
    async getMenu() {
      const res = await columnList();
      if (res.code === 1) {
        this.menu = res.data
      }
      if(this.$route.path === "/list"){
        console.log(this.$route.query.id);
        if(this.$route.query.id === undefined){
          await this.$router.push('/');
          return;
        }
        this.nowMenu = this.findMenu(this.$route.query.id);
        this.activeIndex = this.$route.query.id+"";
      }
      if(this.$route.path === "/search"){
        this.activeIndex = "";
        this.nowMenu = ""
      }
    },
    findMenu(id){
      for (let i = 0; i < this.menu.length; i++) {
        if(Number(this.menu[i].id) === Number(id)){
          localStorage.setItem("nowMenu", JSON.stringify(this.menu[i]));
          this.activeIndex = id;
          this.nowMenu = this.menu[i]
          return this.menu[i]
        }
      }
      localStorage.setItem("nowMenu", "")
      this.activeIndex = "0"
      this.nowMenu = ""
      return "";
    },
    search(){
      if(this.keyword === ""){
        this.$message({
          message: '请输入关键字',
          type: 'warning'
        });
      }else{
        localStorage.setItem("nowMenu", "");
        this.activeIndex = "0";
        this.nowMenu = ""
        this.$router.push({path:'/search',query:{keyword:this.keyword}});
      }
    }
  },
  watch:{
    $route(){
      this.findMenu(this.$route.query.id)
    },
  }
}
</script>

<style scoped>
  .page-header {
    display: flex;
    justify-content: space-around;
    padding: 30px;
  }
  .header-view {
    display: flex;
  }
  .header-text-view {
    margin-top: 10px;
    margin-left: 20px;
  }
  .header-text-view p{
    margin: 0;
    color: #555555;
    font-size: 12px;
  }
  .header-union {
    padding: 5px;
    margin-left: 5px;
  }
  .header-union p {
    color: #555555;
    font-size: 12px;
  }
  .header-search {
    margin-top: 10px;
    display: flex;
  }

  .search-input {
    height: 40px;
  }
  .header-search button {
    background: #1563AA;
    color: white;
    height: 40px;
    border-radius: 0 5px 5px 0;
    border: none;
  }



  .head-logo{
    width: 60px;
  }
  .el-menu-demo{
    display:flex;
    justify-content: center;
  }
  .el-menu-demo .el-menu-item{
    padding: 0 40px;
    font-size: 18px;
  }

  .page-content{
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
</style>
