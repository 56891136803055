import request from '@/utils/request'

export function columnList(query) {
    return request({
        url: '/bluewhale/portal/website/columnList',
        method: 'get',
        params: query
    })
}
export function categoryList(query) {
    return request({
        url: '/bluewhale/portal/website/categoryList',
        method: 'get',
        params: query
    })
}

export function articlePageList(query) {
    return request({
        url: '/bluewhale/portal/website/articlePageList',
        method: 'get',
        params: query
    })
}

export function articleDetailInfo(query) {
    return request({
        url: '/bluewhale/portal/website/articleDetailInfo',
        method: 'get',
        params: query
    })
}

export function bannerList(query) {
    return request({
        url: '/bluewhale/portal/website/bannerList',
        method: 'get',
        params: query
    })
}

export function provinceLibraryCount(query) {
    return request({
        url: '/bluewhale/portal/website/provinceLibraryCount',
        method: 'get',
        params: query
    })
}

export function cityLibrary(query) {
    return request({
        url: '/bluewhale/portal/website/cityLibrary',
        method: 'get',
        params: query
    })
}

export function joinApply(data) {
    return request({
        url: '/bluewhale/portal/website/submit',
        method: 'post',
        data
    })
}

export function libraryList(query) {
    return request({
        url: '/bluewhale/portal/website/libraryList',
        method: 'get',
        params: query
    })
}

export function certPageList(query) {
    return request({
        url: '/bluewhale/portal/website/certPageList',
        method: 'get',
        params: query
    })
}
