<template>
  <div class="page">
    <Header/>
    <app-main />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import AppMain from "@/layout/components/AppMain";

export default {
  name: "index",
  components: {AppMain, Footer, Header},
  data() {
    return {
      banner: [{'img': ""}]
    }
  }
}
</script>

<style scoped>
.el-carousel__item img {
  width: 100%;
}
</style>