<template>
  <div class="page-footer-view">
    <div class="page-footer">
      <div>
        <img src="@/assets/images/logoB.png" height="52" width="235"/>
        <div class="phone-view">
          <div>
            <img src="@/assets/images/img_r12_c5.jpg" height="22" width="23"/>
          </div>
          <div style="display: flex">
            <h1>010-5336</h1>
            <h1>8789</h1>
          </div>
        </div>
        <p>北京市海淀文教产业园F座107室</p>
        <p>Copyright©2021 蓝鲸读书汇教育科技（北京）有限公司</p>
        <a href="https://beian.miit.gov.cn" target="_blank">京ICP备2022008146号-3</a>
      </div>
      <div style="text-align: center;">
        <img src="@/assets/images/qrcode.jpg" width="160"/>
        <p>关注我们公众号</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
.page-footer-view {
  background: #4A4A4A;
  padding: 40px;
}

.page-footer {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.phone-view {
  display: flex;
  color: white;
}

.phone-view > div:first-child {
  margin-top: 32px;
  margin-right: 10px;
}

.page-footer p {
  color: white;
}

.page-footer a {
  color: white;
  text-decoration: none;
}
</style>
